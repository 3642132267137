// Generated by CoffeeScript 1.12.7
/*
Login Form
AJAX is used to submit the user credentials: username and password
Redirect to /demo after successfully authentication
 */
(function() {
  var bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;
  window.LoginForm = (function(superClass) {
    extend(LoginForm, superClass);
    function LoginForm() {
      this.enableForm = bind(this.enableForm, this);
      this.disableForm = bind(this.disableForm, this);
      return LoginForm.__super__.constructor.apply(this, arguments);
    }
    LoginForm.prototype.el = '#form-signin';
    LoginForm.prototype.events = {
      'submit': 'onSubmit'
    };
    LoginForm.prototype.onSubmit = function(e) {
      var data;
      e.preventDefault();
      this.$('.text-error').addClass('hide');
      data = this.$el.serialize();
      this.disableForm();
      return $.post('/login', data, (function(_this) {
        return function(response) {
          var param, ref;
          switch (response.status) {
            case 'ok':
              param = $.url().param();
              return window.location = (ref = param.requrl) != null ? ref : '/';
            case 'err':
              _this.enableForm();
              _this.$('.text-error').text(response.message).removeClass('hide');
              return _this.$('input[type="password"]:last').focus();
          }
        };
      })(this));
    };
    LoginForm.prototype.disableForm = function() {
      this.$('input').attr('disabled', true);
      this.$('button').hide();
      return this.$('.spinbox').show();
    };
    LoginForm.prototype.enableForm = function() {
      this.$('input').attr('disabled', false);
      this.$('button').show();
      return this.$('.spinbox').hide();
    };
    return LoginForm;
  })(Backbone.View);
}).call(this);
